import {
  AlertColor,
  Box,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import { images } from "../../../utils/constants/images";
import { styles } from "../Listing/styles";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { logout } from "../../../utils/redux";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../components/PrimaryButton";
import { Close } from "@mui/icons-material";
import { RoleForm } from "./RoleForm";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import Alert from "../../../components/Alert";

interface Column {
  id: string;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

interface RoleDataById {
  id?: number | string;
  name?: string;
  privileges?: {
    id: string | number;
    name: string;
    selected: boolean;
  }[];
}

interface UserListingProps {
  columns: Column[];
}

interface RoleData {
  id: string;
  sno?: number;
  name: string;
  isRemovable: boolean;
}

const RoleListing: FC<UserListingProps> = ({ columns }) => {
  const rowsPerPage: number = 10;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch();
  const [roleForm, setRoleForm] = useState<any>();
  const [viewRoleModal, setViewRoleModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [roleData, setRoleData] = useState<RoleDataById>({});
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: RoleData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });

  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const openAddRole = () => {
    setOpenModal(true);
  };

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      interface ApiData {
        id: string;
        name: string;
        isRemovable: boolean;
      }

      const {
        data,
      }: {
        data: {
          size: number;
          content: ApiData[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
        };
      } = await axiosInstance.post(URLS.roles_list, body);

      const listings: RoleData[] = [];
      data.content.forEach((listing) => {
        listings.push({
          id: listing.id,
          name: listing.name,
          isRemovable: listing.isRemovable,
        });
      });
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const handleCloseModal = async (flag?: boolean) => {
    if (!!flag) {
      await getData();
    }
    setRoleForm("");
    setOpenModal(false); // Close the modal
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const addRole = async () => {
    try {
      let body;
      if (roleForm?.name && roleForm?.name !== "") {
        body = {
          name: roleForm.name,
          parentRole: null,
        };
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Please enter role name!",
        });
        return;
      }

      setLoading(true);
      await axiosInstance.post(URLS.roles, body);

      setOpenModal(false);
      setRoleForm("");
      setMessage({
        display: true,
        severity: "success",
        message: "Role added successfully",
      });
      await getData();
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  };

  const handleDelete = async (row: RoleData) => {
    Alert({
      title: `Are you Sure you want to Delete this role?`,
      confirmButtonText: "Yes, Delete",
      confirmButtonColor: "#D10724",
      showCancelButton: true,
      cancelButtonText: "No, Take me Back",
      cancelButtonColor: "#D9D9D9",
      reverseButtons: true,
      customClass: { cancelButton: "delete-popup-cancel" },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteRole(row.id as string);
      }
    });
  };

  const deleteRole = async (id: string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.delete(`${URLS.roles}/${id}`);
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Role Deleted Successfully",
        });
        await getData();
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const getRoleById = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const {
          data,
        }: {
          status: number;
          data: RoleDataById;
        } = await axiosInstance.get(`${URLS.roles}/${id}`);
        setRoleData(data);
        setViewRoleModal(true);
        setLoading(false);
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            setMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            });
          }
        }
        setLoading(false);
        console.log(e);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    (async () => {
      if (selectedRoleId) {
        await getRoleById(selectedRoleId);
      }
    })();
  }, [getRoleById, selectedRoleId]);

  const handleRoleCheckbox = (role: {
    id: string | number;
    name: string;
    selected: boolean;
  }) => {
    let updatedCheckBox = roleData?.privileges?.map((data) => {
      if (role.id === data.id) {
        return {
          ...data,
          selected: !data.selected,
        };
      } else {
        return data;
      }
    });

    if (updatedCheckBox) {
      setRoleData({ ...roleData, privileges: updatedCheckBox });
    }
  };

  const handleUpdateRole = async (id: string | number) => {
    if (roleData?.privileges) {
      setLoading(true);
      let body = [];
      for (let role of roleData?.privileges) {
        if (role.selected) {
          body.push(role.id);
        }
      }
      try {
        await axiosInstance.put(`${URLS.roles}/${id}/privileges`, body);
        setMessage({
          display: true,
          severity: "success",
          message: "Role updated successfully",
        });
        setRoleData({});
        setViewRoleModal(false);
        setSelectedRoleId(null);
        setLoading(false);
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            setMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            });
          }
        }
        setLoading(false);
        console.log(e);
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
        marginTop: "20px",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Box textAlign={"right"}>
        <PrimaryButton
          fullWidth={false}
          color={"secondary"}
          sx={{
            // mt: 1,
            borderRadius: "24px 0px 24px 0px",
            textTransform: "capitalize",
          }}
          onClick={openAddRole}
        >
          Add Role
        </PrimaryButton>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
          marginTop: 2,
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 290px)",
            overflow: "auto",
            // marginTop: "20px",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Delete Role
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row, index) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column) => {
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                    };

                    let value;
                    if (column.id === "sno") {
                      value = index + 1;
                    } else {
                      value = row.name;
                    }

                    return (
                      <TableCell
                        key={column.id}
                        // align="center"
                        sx={{ padding: "25px" }}
                      >
                        {column.id === "privileges" ? (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setSelectedRoleId(row.id);
                            }}
                            sx={{
                              borderRadius: "5rem",
                              backgroundColor: "#D10724",
                              paddingX: 4,
                              "&:hover": {
                                backgroundColor: "#D10724",
                              },
                            }}
                          >
                            View
                          </Button>
                        ) : (
                          <Typography sx={textStyle}>{value}</Typography>
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell align={"center"} sx={{ padding: "25px" }}>
                    {!row.isRemovable && (
                      <IconButton
                        key="delete"
                        color="default"
                        aria-label="delete"
                        onClick={async () => {
                          await handleDelete(row);
                        }}
                      >
                        <img
                          src={images.trash}
                          alt="addListing"
                          className={"logo-icon-dashboard"}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            item
            lg={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Modal
        open={viewRoleModal}
        onClose={() => {
          setViewRoleModal(false);
          setSelectedRoleId(null);
          setRoleData({});
        }}
      >
        <Box sx={[{ ...styles.modalContent }, { minWidth: "550px" }]}>
          <IconButton
            onClick={() => {
              setViewRoleModal(false);
              setSelectedRoleId(null);
              setRoleData({});
            }} // Close the modal when IconButton is clicked
            sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
          >
            <Close />
          </IconButton>
          {roleData?.privileges &&
            roleData.privileges.map((role) => {
              return (
                <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                  <Box flex={2} textAlign={"left"} paddingLeft={"30px"}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      textTransform={"capitalize"}
                    >
                      {role.name}
                    </Typography>
                  </Box>

                  <Box flex={2} textAlign={"center"}>
                    <Checkbox
                      onClick={() => {
                        handleRoleCheckbox(role);
                      }}
                      checked={role.selected}
                      sx={{
                        color: "#D10724",
                        "&.Mui-checked": {
                          color: "#D10724",
                        },
                      }}
                    />
                  </Box>
                </Box>
              );
            })}

          <Grid container marginTop={2}>
            <Grid item xs={12} textAlign={"center"}>
              <Button
                disabled={loading}
                variant="contained"
                onClick={() => handleUpdateRole(selectedRoleId as string)}
                sx={{
                  borderRadius: "5rem",
                  backgroundColor: "#D10724",
                  paddingX: 4,
                  "&:hover": {
                    backgroundColor: "#D10724",
                  },
                }}
              >
                Update Role
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => handleCloseModal()} // Close the modal when IconButton is clicked
            sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
          >
            <Close />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textTransform={"capitalize"}
          >
            Add New Role
          </Typography>
          <Divider />
          <Container>
            <Grid container>
              {RoleForm.map((item: FormModel) => ({
                ...item,
                value: !!roleForm
                  ? (roleForm[item.field] as string | boolean)
                  : "",
              })).map((item: FormModel, index: number) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  padding={2}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={"18px"}
                    minWidth={"120px"}
                    color="#545454"
                  >
                    {item.label}
                  </Typography>
                  <RenderFormFields
                    value={item.value as string}
                    item={item}
                    handleChange={(value: string, field: string) => {
                      const data = {
                        [field]: value,
                      };
                      setRoleForm((prevState: any) => ({
                        ...prevState,
                        ...data,
                      }));
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container marginTop={4}>
              <Grid item xs={12} textAlign={"center"}>
                <Button
                  disabled={loading}
                  variant="contained"
                  onClick={addRole}
                  sx={{
                    borderRadius: "5rem",
                    backgroundColor: "#D10724",
                    paddingX: 4,
                    "&:hover": {
                      backgroundColor: "#D10724",
                    },
                  }}
                >
                  Add Role
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </Container>
  );
};

export default RoleListing;
