import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid2 as Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AlertColor,
  Stack,
  Pagination,
  IconButton,
  Box,
} from "@mui/material";
import { logout } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification";
import PageLoader from "../../../components/PageLoader";
import { useDispatch } from "react-redux";
import { styles } from "./styles";
import PrimaryButton from "../../../components/PrimaryButton";
import { routes } from "../../../utils/constants/routes";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import dayjs from "dayjs";

interface Column {
  id: keyof InvoiceList;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "invoiceDate", label: "Invoice Date", minWidth: 200, align: "left" },
  {
    id: "invoiceNo",
    label: "Invoice No.",
    minWidth: 200,
    align: "left",
  },
  {
    id: "orderId",
    label: "Order Id",
    minWidth: 200,
    align: "left",
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 200,
    align: "left",
  },
  {
    id: "customerType",
    label: "Type",
    minWidth: 200,
    align: "left",
  },
  {
    id: "invoiceDate",
    label: "Payment Date",
    minWidth: 200,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 150,
    align: "left",
  },
  {
    id: "invoicePresignedUrl",
    label: "View Invoice",
    minWidth: 150,
    align: "left",
  },
];

export interface InvoiceList {
  amount: number;
  customerName: string;
  customerType: string;
  id: number;
  invoiceDate: string;
  invoiceNo: string;
  invoicePresignedUrl: string;
  invoiceUrl: string;
  orderId: string;
  paymentDate: string;
  paymentMode: string;
  role: string;
}

const Invoices: FC = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: InvoiceList[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const Navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      const {
        data,
      }: {
        data: {
          size: number;
          content: InvoiceList[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
          numberOfElementsInDatabase: number;
        };
      } = await axiosInstance.post(URLS.get_invoice_list, body);

      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings: data.content,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Box display={"flex"} justifyContent={"space-between"} pt={2} pb={2}>
        <Typography variant="h6" component="h6" textTransform={"capitalize"}>
          All Invoices
        </Typography>
        <PrimaryButton
          fullWidth={false}
          color={"secondary"}
          sx={{
            // mt: 1,
            borderRadius: "24px 0px 24px 0px",
            textTransform: "capitalize",
            height: "40px",
          }}
          onClick={() => {
            Navigate(routes.createInvoice);
          }}
        >
          Create Invoice
        </PrimaryButton>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column, index) => {
                    let value = row[column.id as keyof InvoiceList];
                    if (column.id === "invoiceDate") {
                      value = dayjs(
                        new Date(row[column.id as keyof InvoiceList]),
                      ).format("DD/MM/YYYY");
                    }
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        {column.id === "invoicePresignedUrl" ? (
                          <IconButton
                            key="eye-icon"
                            color="default"
                            aria-label="eye"
                            onClick={() => {
                              window.open(value as string);
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        ) : (
                          <Typography sx={textStyle}>{value}</Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            size={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Invoices;
