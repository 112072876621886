const variables = {
  preplixPrimary: "#D10724",
};

export const styles = {
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
  searchBar: {
    background: "#FFFF",
    borderRadius: "12px",
    width: "100%",
    padding: "10px",
    "& .MuiInput-root::before, & .MuiInput-root::after, & .MuiInput-root:hover:not(.Mui-disabled):before":
      {
        borderBottom: "none", // Remove bottom border
      },
  },
  add_user_button: {
    borderRadius: "24px 0px 24px 0px",
    textTransform: "capitalize",
    ml: 1,
    mt: 1,
  },
};
