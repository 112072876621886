import React, { FC } from "react";
import { Card, Grid2 as Grid } from "@mui/material";

import { styles } from "./styles";

const AuthenticationLayout: FC<{ Component: FC }> = ({ Component }) => {
  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid size={{ xs: 12, lg: 5 }} sx={styles.card_container}>
        <Card sx={styles.card}>
          <Component />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
