import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  Container,
  Grid2 as Grid,
  Typography,
  Divider,
  Button,
  Stack,
  Box,
  AlertColor,
} from "@mui/material";
import { variables } from "../../../utils/style/_variables";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import { AddressForm, BankDetails, ProfileForm } from "./profileForm";
import {
  FranchiseProfile,
  logout,
  setProfile,
  UserProfile,
  setFranchiseProfile,
  setNotificationMessage,
} from "../../../utils/redux";
import { ProfileState } from "../../../utils/redux/reducer/profile-slice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import Notification from "../../../components/Notification";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Alert from "../../../components/Alert";
import { PhotoCamera } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { AdditionalFranchiseFields, FranchiseForm } from "./franchiseForm";
import { FranchiseId } from "../../../utils/redux/reducer/authentication-slice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import { FranchiseProfileState } from "../../../utils/redux/reducer/franchise-profile-slice";

const quillModules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["clean"],
  ],
};
const quillFormats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "align",
];

interface ProfileErrorState {
  about: boolean;
  accountNumber: boolean;
  address: boolean;
  bio: boolean;
  city: boolean;
  firstName: boolean;
  lastName: boolean;
  pincode: boolean;
  state: boolean;
}

interface FranchiseErrorState {
  name: boolean;
  title: boolean;
  about: boolean;
  centerUrl: boolean;
  shortDescription: boolean;
  longDescription: boolean;
  city: boolean;
  pincode: boolean;
  address: boolean;
  state: boolean;
  accountNumber: boolean;
  nameAsPerBankAccount: boolean;
  bank: boolean;
  branch: boolean;
  ifcscode: boolean;
}

const Input = styled("input")({
  display: "none",
});

const Profile: FC = () => {
  const dispatch = useDispatch();
  const profile: ProfileState = useSelector(UserProfile);
  const franchise: FranchiseProfileState = useSelector(FranchiseProfile);
  const icons: { [key: string]: ReactNode } = {
    firstName: <PersonOutlineIcon sx={{ color: "red" }} />,
    lastName: <PersonOutlineIcon sx={{ color: "red" }} />,
    email: <EmailOutlinedIcon sx={{ color: "red" }} />,
    phone: <PhoneOutlinedIcon sx={{ color: "red" }} />,
    address: <HomeOutlinedIcon sx={{ color: "red" }} />,
    about: <InfoOutlinedIcon sx={{ color: "red" }} />,
  };
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const franchise_id = useSelector(FranchiseId);
  const [errorState, setErrorState] = useState<ProfileErrorState>({
    about: false,
    accountNumber: false,
    address: false,
    bio: false,
    city: false,
    firstName: false,
    lastName: false,
    pincode: false,
    state: false,
  });
  const [franchiseErrorState, setFranchiseErrorState] =
    useState<FranchiseErrorState>({
      name: false,
      title: false,
      about: false,
      centerUrl: false,
      shortDescription: false,
      longDescription: false,
      city: false,
      pincode: false,
      address: false,
      state: false,
      accountNumber: false,
      nameAsPerBankAccount: false,
      bank: false,
      branch: false,
      ifcscode: false,
    });

  const disableUpdate = () => {
    let invalid = false;
    if (franchise_id !== "PREPLIX") {
      Object.keys(franchiseErrorState).forEach((key: string) => {
        if (franchiseErrorState[key as keyof FranchiseErrorState]) {
          invalid = true;
        }
      });
    } else {
      Object.keys(errorState).forEach((key: string) => {
        if (errorState[key as keyof ProfileErrorState]) {
          invalid = true;
        }
      });
    }
    return invalid;
  };

  const isProfileComplete = () => {
    let complete = true;
    if (franchise_id !== "PREPLIX") {
      Object.keys(franchise).forEach((key: string) => {
        if (!franchise[key as keyof FranchiseProfileState]) {
          complete = false;
        }
      });
    } else {
      Object.keys(profile).forEach((key: string) => {
        if (!profile[key as keyof ProfileState]) {
          complete = false;
        }
      });
    }
    return complete;
  };

  const updateProfile = async () => {
    if (isProfileComplete()) {
      if (franchise_id !== "PREPLIX") {
        await franchiseUpdate();
      } else {
        await confirmUpdate();
      }
    } else {
      Alert({
        title: "Your Profile is incomplete",
        text: "Your listing won't be approved till your profile is complete",
        icon: "warning",
        confirmButtonText: "Never mind, I'll Complete later",
        confirmButtonColor: "#E9D04E",
        showCancelButton: true,
        cancelButtonText: "Complete Profile",
        cancelButtonColor: "#88B28A",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (franchise_id !== "PREPLIX") {
            await franchiseUpdate();
          } else {
            await confirmUpdate();
          }
        }
      });
    }
  };

  const franchiseUpdate = async () => {
    try {
      const body = {
        about: franchise.about || "",
        addressLine: franchise.address || "",
        bankDetails: {
          accountNumber: franchise.accountNumber || "",
          address: franchise.address || "",
          bank: franchise.bank || "",
          branch: franchise.branch || "",
          ifscCode: franchise.ifscCode || "",
          nameAsPerBankAccount: franchise.nameAsPerBankAccount || "",
        },
        centerUrl: franchise.centerUrl || "",
        city: franchise.city || "",
        country: franchise.country || "",
        facilitiesProvided: franchise.facilitiesProvided || "",
        hoursOfOperations: franchise.hoursOfOperations || "",
        locality: franchise.locality || "",
        longDescription: franchise.longDescription || "",
        name: franchise.name || "",
        pinCode: franchise.pincode || "",
        profile: {
          aadhaarNumber: "",
          ein: "",
          gstNo: "",
          panNumber: "",
          proprietorName: "",
          registeredAddress: "",
          registeredBusinessName: "",
          voterId: "",
        },
        programsOffered: franchise.programsOffered || "",
        referralCode: franchise.referralCode || "",
        shortDescription: franchise.shortDescription || "",
        specialFeatures: franchise.specialFeatures || "",
        state: franchise.state || "",
        title: franchise.title || "",
      };
      setLoading(true);
      const { status } = await axiosInstance.put(
        URLS.update_franchise(franchise_id as string),
        body,
      );
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Franchise Updated Successfully",
        });
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const confirmUpdate = async () => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.put(URLS.profile, {
        ...profile,
        country: "India",
        profileComplete: isProfileComplete(),
      });
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Profile Updated Successfully",
        });
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const uploadImage = async (e: any) => {
    const image = e.target.files[0];
    setLoading(true);
    const formData = new FormData();
    formData.append("picture", image);
    try {
      const { status } = await axiosInstance.put(
        `${URLS.profile}/profile-picture?deletePic=false`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Profile Picture Uploaded Successfully",
        });
        e.target.value = null;
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const getFranchiseProfile = useCallback(
    async (user_profile: ProfileState, user_franchise_id: string) => {
      try {
        setLoading(true);
        const { status, data } = await axiosInstance.get(
          URLS.get_franchise_profile(user_franchise_id),
        );
        if (status === 200) {
          console.log({
            ...data,
            email: user_profile.email,
            phone: user_profile.phone,
            address: data.addressLine,
            pincode: data.pinCode,
            nameAsPerBankAccount: data?.bankDetails?.nameAsPerBankAccount,
            branch: data?.bankDetails?.branch,
            accountNumber: data?.bankDetails?.accountNumber,
            bank: data?.bankDetails?.bank,
            ifscCode: data?.bankDetails?.ifscCode,
          });
          dispatch(
            setFranchiseProfile({
              ...data,
              email: user_profile.email,
              phone: user_profile.phone,
              address: data.addressLine,
              pincode: data.pinCode,
              nameAsPerBankAccount: data?.bankDetails?.nameAsPerBankAccount,
              branch: data?.bankDetails?.branch,
              accountNumber: data?.bankDetails?.accountNumber,
              bank: data?.bankDetails?.bank,
              ifscCode: data?.bankDetails?.ifscCode,
            }),
          );
          setLoading(false);
        }
      } catch (e: any) {
        console.log(e);
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: e.response.data.message,
              }),
            );
          }
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            }),
          );
        }
        setLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    (async () => {
      if (
        !!profile &&
        !!profile.firstName &&
        !!profile.lastName &&
        !!profile.email &&
        !!profile.phone &&
        franchise_id !== "PREPLIX"
      ) {
        await getFranchiseProfile(profile, franchise_id as string);
      }
    })();
  }, [getFranchiseProfile, profile, franchise_id]);

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px 30px",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {!loading && (
        <>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, lg: 4 }}>
              <Typography fontSize={"32px"} fontWeight={700} color={"black"}>
                {"Profile"}
              </Typography>
              <Divider
                sx={{
                  width: "20%",
                  height: "3px",
                  background: `${variables.preplixPrimary}`,
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, lg: 8 }} textAlign={"end"} mt={4}>
              <Button
                sx={[
                  {
                    borderRadius: "13px",
                    padding: "8px 50px",
                    backgroundColor: `white`,
                    color: `${variables.preplixPrimary}`,
                    fontWeight: 700,
                    fontSize: "16px",
                    boxShadow: "none",
                    marginRight: "25px",
                    "&:hover": {
                      backgroundColor: `white`,
                      boxShadow: "none",
                    },
                  },
                ]}
                variant="contained"
                startIcon={<LockOutlinedIcon />}
              >
                <Typography fontSize={"16px"} fontWeight={700}>
                  Change Password
                </Typography>
              </Button>

              <Button
                sx={[
                  {
                    borderRadius: "13px",
                    padding: "8px 50px",
                    backgroundColor: `${variables.preplixPrimary}`,
                    boxShadow: "none",

                    "&:hover": {
                      backgroundColor: `${variables.preplixPrimary}`,
                      boxShadow: "none",
                    },
                  },
                ]}
                variant="contained"
                disabled={disableUpdate()}
                onClick={updateProfile}
              >
                <Typography fontSize={"16px"} fontWeight={700}>
                  Update Profile
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={4}>
            {franchise_id !== "PREPLIX"
              ? FranchiseForm()
                  .map((item: FormModel) => ({
                    ...item,
                    value: !!franchise
                      ? franchise[item.field as keyof FranchiseProfileState]
                      : "",
                    startAdornment: icons[item.field],
                  }))
                  .map((item: FormModel, index: number) => (
                    <Grid key={index} size={{ xs: 12, lg: 3 }} marginBottom={3}>
                      <Typography
                        ml={2}
                        mb={1}
                        color={"black"}
                        fontWeight={600}
                        fontSize={"16px"}
                      >
                        {item.label}
                      </Typography>
                      <RenderFormFields
                        value={item.value as string}
                        item={item}
                        handleChange={(
                          value: string,
                          field: string,
                          error?: { error: boolean; message: string },
                        ) => {
                          if (value !== item.value) {
                            dispatch(
                              setFranchiseProfile({
                                ...franchise,
                                [field]: value,
                              }),
                            );
                            setFranchiseErrorState((prevState) => ({
                              ...prevState,
                              [field]: !!(error && error.error),
                            }));
                          }
                        }}
                      />
                    </Grid>
                  ))
              : ProfileForm.map((item: FormModel) => ({
                  ...item,
                  value: !!profile
                    ? profile[item.field as keyof ProfileState]
                    : "",
                  startAdornment: icons[item.field],
                })).map((item: FormModel, index: number) => (
                  <Grid key={index} size={{ xs: 12, lg: 3 }} marginBottom={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={(
                        value: string,
                        field: string,
                        error?: { error: boolean; message: string },
                      ) => {
                        if (value !== item.value) {
                          dispatch(setProfile({ ...profile, [field]: value }));
                          setErrorState((prevState) => ({
                            ...prevState,
                            [field]: !!(error && error.error),
                          }));
                        }
                      }}
                    />
                  </Grid>
                ))}
          </Grid>
          <Grid container spacing={2} mt={4}>
            <Grid size={{ xs: 12, lg: 8 }}>
              {franchise_id !== "PREPLIX" && (
                <Stack>
                  <Box mb={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                    >
                      {AdditionalFranchiseFields.short_desc.label}
                    </Typography>
                    <RenderFormFields
                      value={franchise.shortDescription}
                      item={{
                        ...AdditionalFranchiseFields.short_desc,
                        startAdornment: icons.about,
                      }}
                      handleChange={(
                        value: string,
                        field: string,
                        error?: { error: boolean; message: string },
                      ) => {
                        if (value !== franchise.shortDescription) {
                          dispatch(
                            setFranchiseProfile({
                              ...franchise,
                              shortDescription: value,
                            }),
                          );
                          setFranchiseErrorState((prevState) => ({
                            ...prevState,
                            [field]: !!(error && error.error),
                          }));
                        }
                      }}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      color={"white"}
                      fontWeight={600}
                      fontSize={"16px"}
                      padding={"10px 20px"}
                      sx={{
                        backgroundColor: `${variables.preplixPrimary}`,
                        borderTopLeftRadius: "13px",
                        borderTopRightRadius: "13px",
                      }}
                    >
                      {AdditionalFranchiseFields.long_des.label}
                    </Typography>
                    <RenderFormFields
                      value={franchise.longDescription}
                      item={AdditionalFranchiseFields.long_des}
                      handleChange={(
                        value: string,
                        field: string,
                        error?: { error: boolean; message: string },
                      ) => {
                        if (value !== franchise.longDescription) {
                          dispatch(
                            setFranchiseProfile({
                              ...franchise,
                              longDescription: value,
                            }),
                          );
                          setFranchiseErrorState((prevState) => ({
                            ...prevState,
                            [field]: !!(error && error.error),
                          }));
                        }
                      }}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      mt={3}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                    >
                      About Facility
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      className="custom-quill"
                      value={franchise.facilitiesProvided}
                      onChange={(value) => {
                        dispatch(
                          setFranchiseProfile({
                            ...franchise,
                            facilitiesProvided: value,
                          }),
                        );
                      }}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                      mt={3}
                    >
                      Hours Of Operation
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      className="custom-quill"
                      value={franchise.hoursOfOperations}
                      onChange={(value) => {
                        if (value !== franchise.hoursOfOperations) {
                          dispatch(
                            setFranchiseProfile({
                              ...franchise,
                              hoursOfOperations: value,
                            }),
                          );
                        }
                      }}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      mt={3}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                    >
                      Program Offered
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      className="custom-quill"
                      value={franchise.programsOffered}
                      onChange={(value) => {
                        if (value !== franchise.programsOffered) {
                          dispatch(
                            setFranchiseProfile({
                              ...franchise,
                              programsOffered: value,
                            }),
                          );
                        }
                      }}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      ml={2}
                      mb={1}
                      mt={3}
                      color={"black"}
                      fontWeight={600}
                      fontSize={"16px"}
                    >
                      Special Features
                    </Typography>
                    <ReactQuill
                      theme="snow"
                      className="custom-quill"
                      value={franchise.specialFeatures}
                      onChange={(value) => {
                        if (value !== franchise.specialFeatures) {
                          dispatch(
                            setFranchiseProfile({
                              ...franchise,
                              specialFeatures: value,
                            }),
                          );
                        }
                      }}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                </Stack>
              )}
              <Grid container>
                <Grid size={12} marginBottom={3}>
                  <Typography
                    ml={2}
                    mb={1}
                    color={"black"}
                    fontWeight={600}
                    fontSize={"16px"}
                  >
                    Address
                  </Typography>
                  <Grid container spacing={2} columns={12}>
                    {AddressForm(
                      icons.address,
                      franchise_id !== "PREPLIX"
                        ? franchise.state
                        : profile.state,
                      franchise_id !== "PREPLIX",
                    )
                      .map((item: FormModel) => ({
                        ...item,
                        value:
                          !!franchise && franchise_id !== "PREPLIX"
                            ? franchise[
                                item.field as keyof FranchiseProfileState
                              ]
                            : !!profile
                              ? profile[item.field as keyof ProfileState]
                              : "",
                      }))
                      .map((item: FormModel, index: number) => (
                        <Grid key={index} size={{ xs: 12, lg: 6 }}>
                          <Typography
                            ml={2}
                            mb={1}
                            color={"black"}
                            fontWeight={600}
                            fontSize={"16px"}
                          >
                            {item.label}
                          </Typography>
                          <RenderFormFields
                            value={item.value as string}
                            item={item}
                            handleChange={(
                              value: string,
                              field: string,
                              error?: { error: boolean; message: string },
                            ) => {
                              if (value !== item.value) {
                                if (franchise_id !== "PREPLIX") {
                                  dispatch(
                                    setFranchiseProfile({
                                      ...franchise,
                                      [field]: value,
                                    }),
                                  );
                                  setFranchiseErrorState((prevState) => ({
                                    ...prevState,
                                    [field]: !!(error && error.error),
                                  }));
                                } else {
                                  dispatch(
                                    setProfile({ ...profile, [field]: value }),
                                  );
                                  setErrorState((prevState) => ({
                                    ...prevState,
                                    [field]: !!(error && error.error),
                                  }));
                                }
                              }
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid size={12}>
                  <Typography
                    color={"white"}
                    fontWeight={600}
                    fontSize={"16px"}
                    padding={"15px 20px"}
                    sx={{
                      backgroundColor: `${variables.preplixPrimary}`,
                      borderTopLeftRadius: "13px",
                      borderTopRightRadius: "13px",
                    }}
                  >
                    Bank Details for Payout
                  </Typography>

                  <Grid
                    sx={{
                      backgroundColor: "white",
                      borderBottomLeftRadius: "13px",
                      borderBottomRightRadius: "13px",
                    }}
                    container
                    spacing={2}
                    columns={12}
                  >
                    {BankDetails.map((item: FormModel) => ({
                      ...item,
                      value:
                        !!franchise && franchise_id !== "PREPLIX"
                          ? franchise[item.field as keyof FranchiseProfileState]
                          : !!profile
                            ? profile[item.field as keyof ProfileState]
                            : "",
                    })).map((item: FormModel, index: number) => (
                      <Grid key={index} size={{ xs: 12, lg: 6 }} padding={2}>
                        <Typography
                          ml={2}
                          mb={1}
                          color={"black"}
                          fontWeight={600}
                          fontSize={"16px"}
                        >
                          {item.label}
                        </Typography>
                        <RenderFormFields
                          value={item.value as string}
                          item={item}
                          handleChange={(
                            value: string,
                            field: string,
                            error?: { error: boolean; message: string },
                          ) => {
                            if (value !== item.value) {
                              if (franchise_id !== "PREPLIX") {
                                dispatch(
                                  setFranchiseProfile({
                                    ...franchise,
                                    [field]: value,
                                  }),
                                );
                                setFranchiseErrorState((prevState) => ({
                                  ...prevState,
                                  [field]: !!(error && error.error),
                                }));
                              } else {
                                dispatch(
                                  setProfile({ ...profile, [field]: value }),
                                );
                                setErrorState((prevState) => ({
                                  ...prevState,
                                  [field]: !!(error && error.error),
                                }));
                              }
                            }
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, lg: 4 }}>
              <Stack
                direction={"row"}
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  backgroundColor: `${variables.preplixPrimary}`,
                  borderTopLeftRadius: "13px",
                  borderTopRightRadius: "13px",
                  paddingY: "10px",
                }}
              >
                <Box>
                  <Typography
                    color={"white"}
                    fontWeight={600}
                    fontSize={"16px"}
                    padding={"10px 20px"}
                  >
                    Upload Profile Picture
                  </Typography>
                </Box>
              </Stack>
              <Stack
                justifyContent={"center"}
                textAlign={"center"}
                padding={3}
                paddingTop={0}
                sx={{
                  minHeight: "100px",
                  backgroundColor: `white`,
                  borderBottomLeftRadius: "13px",
                  borderBottomRightRadius: "13px",
                }}
              >
                <Box padding={3}>
                  <img
                    className="profile-img"
                    src={profile.profileUrl}
                    alt={`tutor`}
                    width={215}
                    height={203}
                  />
                </Box>

                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={uploadImage}
                  />
                  <Button variant="contained" component="span">
                    <PhotoCamera sx={{ paddingRight: "5px" }} />{" "}
                    {!!profile.profileUrl
                      ? " Upload New Photo"
                      : " Upload Photo"}
                  </Button>
                </label>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Profile;
