import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import authenticationSlice from "../reducer/authentication-slice";
import notificationSlice from "../reducer/notification-slice";
import profileSlice from "../reducer/profile-slice";
import franchiseProfileSlice from "../reducer/franchise-profile-slice";

const store: EnhancedStore = configureStore({
  reducer: {
    authenticationSlice,
    notificationSlice,
    profileSlice,
    franchiseProfileSlice,
  },
});

export default store;
