import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid2 as Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AlertColor,
  Stack,
  Pagination,
  IconButton,
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import { logout } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification";
import PageLoader from "../../../components/PageLoader";
import { useDispatch } from "react-redux";
import { styles } from "./styles";
import DropDownInput from "../../../components/DropDownInput";
import { leads_status } from "../../../utils/constants/constants";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PrimaryButton from "../../../components/PrimaryButton";

interface Column {
  id: keyof LeadsListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "id", label: "ID", minWidth: 150, align: "left" },
  { id: "name", label: "Name", minWidth: 250, align: "left" },
  {
    id: "email",
    label: "Email",
    minWidth: 200,
    align: "left",
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 200,
    align: "left",
  },
  {
    id: "lookingFor",
    label: "Looking For",
    minWidth: 200,
    align: "left",
  },
  {
    id: "hasCompletedBachelorsDegree",
    label: "Bachelors Degree",
    minWidth: 200,
    align: "left",
  },
  {
    id: "workSector",
    label: "Work Sector",
    minWidth: 200,
    align: "left",
  },
  {
    id: "reference",
    label: "Reference",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "Lead Status",
    minWidth: 100,
    align: "left",
  },
];

export interface LeadsListingData {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: number;
  franchiseReferralCode: string;
  userReferralCode: string;
  lookingFor: string;
  hasCompletedBachelorsDegree: string;
  workSector: string;
  reference: string;
  status: string;
}

const Leads: FC = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: LeadsListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [filterText, setFilterText] = useState<string>("");

  const getData = useCallback(
    async (filter: string[]) => {
      try {
        setLoading(true);
        const body = {
          filter,
          graphql: null,
          page: page,
          size: rowsPerPage,
          sort: [`createdAt:desc`],
        };

        const {
          data,
        }: {
          data: {
            size: number;
            content: LeadsListingData[];
            number: number;
            sort: { sorted: boolean; empty: boolean; unsorted: boolean };
            first: boolean;
            last: true;
            totalPages: number;
            totalElements: number;
            numberOfElements: number;
            numberOfElementsInDatabase: number;
          };
        } = await axiosInstance.post(URLS.leads("page"), body);
        setListing({
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          listings: data.content.map((list) => ({
            ...list,
            name: `${list.firstName} ${list.lastName}`,
          })),
        });
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        if (e.response) {
          if (e.response.status === 401) {
            dispatch(logout());
          } else {
            setMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            });
          }
        }
        console.log(e);
      }
    },
    [dispatch, page],
  );

  useEffect(() => {
    (async () => {
      await getData([]);
    })();
  }, [getData]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const updateLeadStatus = async (value: string, id: number) => {
    try {
      setLoading(true);
      await axiosInstance.put(URLS.leads(`${id}/status`), { status: value });
      setMessage({
        display: true,
        severity: "success",
        message: "Lead Status Update Successfully",
      });
      await getData([]);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };

  const convertUser = async (id: number) => {
    try {
      setLoading(true);
      await axiosInstance.put(URLS.leads(`${id}/convert`));
      setMessage({
        display: true,
        severity: "success",
        message: "Lead Converted as User Successfully",
      });
      await getData([]);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Typography
        variant="h6"
        component="h6"
        p={3}
        textTransform={"capitalize"}
      >
        Leads Generated
      </Typography>
      <Stack direction={"row"} my={2}>
        <Box>
          <TextField
            style={styles.searchBar}
            placeholder="ID"
            variant="standard"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">PLC-</InputAdornment>
                ),
              },
            }}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Box>
        <Box>
          <PrimaryButton
            fullWidth={false}
            color={"secondary"}
            sx={styles.add_user_button}
            onClick={() => getData(!!filterText ? [`id:eq:${filterText}`] : [])}
          >
            Search
          </PrimaryButton>
        </Box>
      </Stack>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column, index) => {
                    const value =
                      column.id !== "id"
                        ? column.id !== "lookingFor"
                          ? column.id !== "hasCompletedBachelorsDegree"
                            ? row[column.id as keyof LeadsListingData]
                            : row[column.id as keyof LeadsListingData] === "yes"
                              ? "Completed"
                              : row[column.id as keyof LeadsListingData]
                          : row[column.id as keyof LeadsListingData] ===
                              "study_abroad"
                            ? "Study Abroad"
                            : "Work Abroad"
                        : "PLC-" + row[column.id as keyof LeadsListingData];
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                    };
                    if (column.id !== "email") {
                      textStyle = {
                        ...textStyle,
                        textTransform: "capitalize",
                      };
                    }
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        {column.id !== "status" ? (
                          <Typography sx={textStyle}>
                            {value as unknown as string}
                          </Typography>
                        ) : (
                          <DropDownInput
                            disabled={loading}
                            options={leads_status}
                            textChange={(value) =>
                              updateLeadStatus(value, row.id)
                            }
                            inputValue={row.status}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell align={"center"} sx={{ padding: "25px" }}>
                    {/* Edit button */}
                    <IconButton
                      key="edit"
                      color="default"
                      aria-label="edit"
                      onClick={() => convertUser(row.id)}
                    >
                      <DoneAllIcon color={"primary"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            size={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Leads;
