import { createSlice } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";

interface NotificationState {
  display: boolean;
  severity: AlertColor | null;
  message: string;
}

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    display: false,
    severity: null,
    message: "",
  },
  reducers: {
    setNotificationMessage(state, action) {
      state.display = action.payload.display;
      state.severity = action.payload.severity || null;
      state.message = action.payload.message || "";
    },
  },
});

export default notificationSlice.reducer;

export const { setNotificationMessage } = notificationSlice.actions;

export const NotificationDisplay = (state: {
  notificationSlice: NotificationState;
}) => state.notificationSlice.display;

export const NotificationSeverity = (state: {
  notificationSlice: NotificationState;
}) => state.notificationSlice.severity;

export const NotificationMessage = (state: {
  notificationSlice: NotificationState;
}) => state.notificationSlice.message;
