import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";
import { DropDown } from "../../../utils/constants/identifiers";

export const UserForm: (role: string, roles: DropDown[]) => FormModel[] = (
  role,
  roles,
) => {
  const form: FormModel[] = [
    {
      label: "First Name",
      value: "",
      size: "small",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "First Name",
      field: "firstName",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Last Name",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Last Name",
      field: "lastName",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Email",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Email",
      field: "email",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Phone Number",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Phone Number",
      field: "phoneNumber",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "30px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "30px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "40px",
          paddingRight: "40px",
        },
      },
      hiddenLabel: true,
    },
  ];

  if (role === "staff") {
    form.push({
      label: "Role",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "role",
      options: roles,
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "30px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    });
  }

  return form;
};
