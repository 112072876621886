import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import PeopleIcon from "@mui/icons-material/People";
import { Grid2 as Grid } from "@mui/material";
import { FC } from "react";
import { variables } from "../../utils/style/_variables";

interface DashboardCardProps {
  name: string;
  count: number;
}

const DashboardCard: FC<DashboardCardProps> = ({ name, count }) => {
  return (
    <Card
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 4px 4px 0px #2D83C526",
      }}
    >
      <Grid container padding={4}>
        <Grid size={{ xs: 12, lg: 5 }}>
          <PeopleIcon sx={{ fontSize: "55px", color: "#9D9D9D" }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 7 }} textAlign={"center"}>
          <Typography
            gutterBottom
            fontSize={"20px"}
            color={variables.preplixPrimary}
            fontWeight={"600"}
          >
            {count}
          </Typography>
          <Typography
            gutterBottom
            fontSize={"14px"}
            color={"#000000"}
            fontWeight={500}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DashboardCard;
