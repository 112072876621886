import React, { FC, useCallback, useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  Container,
  TextField,
  Grid2 as Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Authenticated,
  logout,
  setProfile,
  UserProfile,
  setNotificationMessage,
} from "../../utils/redux";
import axiosInstance from "../../utils/axios";
import { URLS } from "../../utils/constants/urls";
import { images } from "../../utils/constants/images";
import { routes } from "../../utils/constants/routes";
import PageLoader from "../../components/PageLoader";
import { styles } from "./styles";
import { FranchiseId } from "../../utils/redux/reducer/authentication-slice";

const drawerWidth = 240;

const sideList: SideBarList[] = [
  {
    name: "Dashboard",
    icon: images.dbDash,
    route: routes.dashboard,
  },
  {
    name: "Profile",
    icon: images.dbProfile,
    route: routes.profile,
  },
  {
    name: "Listing",
    icon: images.dbListing,
    route: routes.listing,
  },
  {
    name: "Applications",
    icon: images.dbStApp,
    route: routes.applications,
  },
  {
    name: "Leads",
    icon: images.dbStList,
    route: routes.leads,
  },
  {
    name: "Teacher List",
    icon: images.dbStList,
    route: routes.teacher_listing,
  },
  {
    name: "Student List",
    icon: images.dbStList,
    route: routes.student_listing,
  },
  {
    name: "Reports",
    icon: images.dbSubs,
    route: "",
  },
  {
    name: "Revenue",
    icon: images.dbIncome,
    route: "",
  },
  {
    name: "Invoices",
    icon: images.dbIncome,
    route: routes.invoices,
  },
  {
    name: "User Management",
    icon: images.user,
    route: routes.user_management,
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface SideBarList {
  name: string;
  icon: string;
  route: string;
}

const PrivateRoute: FC<{ Component: FC }> = ({ Component }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const is_authenticated = useSelector(Authenticated);
  const profile = useSelector(UserProfile);
  const franchise_id = useSelector(FranchiseId);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [sideBarList, setSideBarList] = useState<SideBarList[]>(sideList);

  const getProfile = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.profile);
      if (status === 200) {
        dispatch(
          setProfile({
            ...data,
            address: !!data.address ? data.address.addressLine : "",
            city: !!data.address ? data.address.city : "",
            state: !!data.address ? data.address.state : "",
            pincode: !!data.address ? data.address.pincode : "",
            accountNumber: !!data.bankDetails
              ? data.bankDetails.accountNumber
              : "",
            bank: !!data.bankDetails ? data.bankDetails.bank : "",
            branch: !!data.bankDetails ? data.bankDetails.branch : "",
            ifscCode: !!data.bankDetails ? data.bankDetails.ifscCode : "",
            nameAsPerBankAccount: !!data.bankDetails
              ? data.bankDetails.nameAsPerBankAccount
              : "",
            id: !!data.id ? data.id : "",
          }),
        );
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            }),
          );
        }
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          }),
        );
      }
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!is_authenticated) {
      Navigate(routes.login);
    } else {
      (async () => {
        await getProfile();
        if (franchise_id === "PREPLIX") {
          setSideBarList((prevState) => [
            ...prevState,
            {
              name: "Roles",
              icon: images.user,
              route: routes.role_management,
            },
            // {
            //       name: "Franchise",
            //       icon: images.user,
            //       route: routes.franchise,
            //     }
          ]);
        }
      })();

      setLoading(false);
    }
  }, [is_authenticated, getProfile, Navigate, franchise_id]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading && <PageLoader />}
      {!loading && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            sx={[
              { backgroundColor: "#DEEDF7", boxShadow: "none" },
              styles.headerCont,
            ]}
          >
            <Toolbar>
              <Container maxWidth={false} disableGutters={true}>
                <Grid container spacing={2}>
                  <Grid
                    size={{ xs: 12, lg: 8 }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <IconButton sx={[{ mr: 5 }]}>
                      <img
                        src={images.logo}
                        alt="logoImage"
                        className={"logo-icon-dashboard"}
                        loading="lazy"
                      />
                    </IconButton>
                    <Typography
                      variant="h6"
                      color={"black"}
                      fontWeight={600}
                      fontSize={"24px"}
                      lineHeight={"40.22px"}
                      letterSpacing={"2%"}
                      noWrap
                    >
                      "Hi,{" "}
                      {!!profile
                        ? `${profile.firstName} ${profile.lastName}`
                        : ""}{" "}
                      👋
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, lg: 4 }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid size={{ xs: 12, lg: 8 }}>
                        <TextField
                          sx={[styles.searchBar]}
                          placeholder="search"
                          variant="standard"
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    edge="start"
                                    sx={[{ marginLeft: "0px" }]}
                                    color="primary"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        size={{ xs: 12, lg: 4 }}
                        justifyContent={"end"}
                        display={"flex"}
                      >
                        <Box sx={[styles.navIcon, { mr: 2 }]}>
                          <img
                            src={images.notify}
                            alt={"notifications"}
                            loading="lazy"
                          />
                        </Box>
                        <Box sx={[styles.navIcon]}>
                          <img
                            src={images.dbSetting}
                            alt={"dbSettings"}
                            loading="lazy"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            sx={[styles.sidebarCont]}
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
          >
            <DrawerHeader>{/* profile picture of admin */}</DrawerHeader>
            <Divider />
            <List
              sx={{
                maxHeight: "calc(100vh - 150px)",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {sideBarList.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "block", py: 1, textTransform: "initial" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                    }}
                    href={item.route}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={styles.sideIcon}
                        src={item.icon}
                        alt={`sideList-${index + 1}`}
                        loading="lazy"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem
                disablePadding
                sx={{
                  display: "block",
                  pt: 3,
                  pb: 2,
                  textTransform: "initial",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => dispatch(logout())}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={styles.sideIcon}
                      src={images.logout}
                      alt={`logout-icon`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Logout"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <DrawerHeader />
            {<Component />}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PrivateRoute;
