import React, { FC, ReactNode } from "react";
import { Button as CustomButton, ButtonProps } from "@mui/material";
import { styles } from "./styles";

interface OwnProps {
  // leftIcon?: string;
  children: ReactNode;
  sx?: any;
  variant?: "text" | "outlined" | "contained";
  rest?: any;
  onClick?: any;
  disabled?: boolean;
  style?: any;
  customStyle?: any;
}

const Button: FC<OwnProps & ButtonProps> = ({
  children,
  variant = "text",
  onClick,
  disabled = false,
  customStyle,
  sx,
  ...rest
}) => {
  return (
    <CustomButton
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      sx={[{ opacity: disabled ? "0.5" : "1" }, styles.button_wrapper, ...sx]}
      {...rest}
    >
      {children}
    </CustomButton>
  );
};
export default Button;
