import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {images} from "../../utils/constants/images";

export const styles: { [key: string]: SxProps<Theme> } = {
    container: {
        background: `url(${images.login_bg}) no-repeat`,
        minHeight: '100vh',
        backgroundSize: {lg: '60% 80%'},
        backgroundPosition: {xs: "-240px -260px", lg: 'unset'},
        justifyContent: 'flex-end',
        padding: 0,
        flexDirection: {xs: 'column', lg: 'row'},
    },
    card_container: {
        alignSelf: 'center',
        p: {xs: 0, lg: 5}
    },
    card: {
        p: {xs: 2, lg: 5},
        borderRadius: {xs: "24px 24px 0px 0px ", lg: "24px"},
        width: "100%",
    }
}
