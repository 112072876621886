import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";

export const ApplicationsForm: () => FormModel[] = () => {
  return [
    {
      label: "Student Name",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "studentName",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Teacher Name",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "teacherName",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Country",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "country_name",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "University Preference",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "University Preference",
      field: "university_preference_name",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Preferred Course",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "preferred_course_name",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Annual Fees Budget",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "annualFeeBudget",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        { check: Validators.number, message: "Please enter a Valid Budget" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Admission Plan",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "admissionPlan",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "IELTS Attempted",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "ieltsAttempted",
      options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "IELTS Score (Optional)",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "ieltsScore",
      validators: [],
      responsive: { xs: 12 },
      required: false,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: true,
    },
    {
      label: "Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "status",
      options: [
        { label: "Pending", value: "PENDING" },
        { label: "Approved", value: "APPROVED" },
        { label: "Accepted", value: "ACCEPTED" },
        { label: "Document Verification", value: "VERIFICATION" },
        { label: "Application Submitted", value: "SUBMITTED" },
        { label: "Clarification Needed", value: "RE-VERIFY" },
        { label: "UL Received", value: "UL-RECEIVED" },
        { label: "UL Accepted", value: "UL-ACCEPTED" },
      ],
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];
};
