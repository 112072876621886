const variables = {
  preplixPrimary: "#D10724",
};

export const styles = {
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
};
