import { FormModel } from "../../../components/Form";
import { DropDown } from "../../../utils/constants/identifiers";
import { Validators } from "../../../utils/validators";

export const CreateInvoiceForm: (
  customerType: string | undefined,
  customerTypesDropdown: DropDown[],
  usersDropdown: DropDown[]
) => FormModel[] = (customerType, customerTypesDropdown, usersDropdown) => {
  const form: FormModel[] = [
    {
      label: "Cutomer Type",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Please select customer type",
      field: "customerType",
      options: customerTypesDropdown,
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: customerType ? `${customerType} Name` : "Name",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Please select the Name",
      field: "userId",
      options: usersDropdown,
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];

  if (customerType === "FRANCHISE") {
    form.push({
      label: "GST IN",
      value: "",
      size: "small",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Please enter GST",
      field: "gstIn",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    });
  }

  form.push(
    {
      label: "Invoice Date",
      validators: [],
      required: true,
      hiddenLabel: true,
      value: "",
      field: "invoiceDate",
      type: "date",
      placeholder: "",
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
    },
    {
      label: "Payment Method / Mode",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Please select payment method",
      field: "paymentMethod",
      options: [
        { label: "Online", value: "Online" },
        { label: "Cash", value: "Cash" },
        { label: "Cheque", value: "Cheque" },
        { label: "Paid at Center", value: "Paid at Center" },
      ],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    }
  );
  return form;
};