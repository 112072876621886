import Axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { identifiers } from "../constants/identifiers";
import { fetchFromStorage } from "../storage";

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL || window.location.origin,
  headers: {
    "Content-Type": "application/json",
  },
  params: { lang: "en_IN", t: new Date().getTime() },
});

axiosInstance.defaults.timeout = 300000;

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = fetchFromStorage(identifiers.access_token as string, true);
  const clonedConfig = config;

  if (token) {
    clonedConfig.headers.set({
      ...clonedConfig.headers,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    });
  }

  return clonedConfig;
});

axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
  return Promise.reject(error);
});

export default axiosInstance;
