import { FC } from "react";
import RoleListing from "./RoleListing";

interface Column {
  id: string;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const RoleManagement: FC = () => {
  const Columns: Column[] = [
    { id: "sno", label: "S.No", minWidth: 50, align: "left" },
    { id: "name", label: "Role Name", minWidth: 150, align: "left" },
    { id: "privileges", label: "Privileges", minWidth: 100, align: "left" },
  ];

  return <RoleListing columns={Columns} />;
};

export default RoleManagement;
