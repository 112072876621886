import { FC } from "react";

import UserListing from "../../Common/UserListing/UserListing";

interface Column {
  id: keyof StudentData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const StudentColumns: Column[] = [
  { id: "id", label: "Student ID", minWidth: 100, align: "left" },
  { id: "name", label: "Name", minWidth: 150, align: "left" },
  { id: "email", label: "Email", minWidth: 150, align: "left" },
  { id: "phone", label: "Mobile Number", minWidth: 100, align: "left" },
];

export interface StudentData {
  id?: string;
  name: string;
  email: string;
  phone: string;
}

const StudentList: FC = () => {
  return <UserListing columns={StudentColumns} title={"student"} />;
};
export default StudentList;
