import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SwalAlert = withReactContent(Swal);

const Alert: (props: SweetAlertOptions) => Promise<SweetAlertResult> = ({
  text,
  title,
  icon,
  ...rest
}) => {
  return SwalAlert.fire({
    title,
    text: !!text ? text : "Something went wrong",
    icon,
    allowOutsideClick: false,
    ...rest,
  });
};

export default Alert;
