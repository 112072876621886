import { FormModel } from "../../../components/Form";

export const RoleForm: FormModel[] = [
  {
    label: "Role Name",
    value: "",
    size: "small",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Enter role name",
    field: "name",
    validators: [],
    responsive: { xs: 12 },
    required: false,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "30px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "30px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        paddingLeft: "40px",
      },
    },
    hiddenLabel: true,
  },
];
