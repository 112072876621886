import React, { FC, SyntheticEvent } from "react";
import { AlertColor, Box, Snackbar, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface NotificationProps {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
  autoHideDuration?: number;
  closeNotification: (value: boolean) => void;
}

const Notification: FC<NotificationProps> = ({
  isOpen,
  message,
  severity,
  // autoHideDuration,
  closeNotification,
}) => {
  let color = "#5AB9F9";

  if (severity === "warning") {
    color = "#F4D556";
  } else if (severity === "error") {
    color = "#EB0000";
  }

  // let duration: number | null = 6000;

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    closeNotification(false);
  };

  // if (autoHideDuration !== undefined) {
  //   duration = autoHideDuration;
  // }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Stack
        direction={"column"}
        minHeight={"100px"}
        minWidth={"200px"}
        sx={{
          flexDirection: "column",
          borderRadius: "15px",
          border: `1px solid ${color}`,
          background: "white",
        }}
      >
        <Box
          sx={{
            background: `${color}`,
            borderRadius: `15px 15px 0px 0px`,
            padding: "3% 5%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              color: "white",
              textTransform: "uppercase",
            }}
          >
            {severity}
          </Typography>
          <CloseIcon
            onClick={handleClose}
            sx={{ color: "white", cursor: "pointer" }}
          />
        </Box>
        <Box padding={"5%"}>
          <Typography variant="h6" sx={{ fontSize: "12px" }}>
            {message}
          </Typography>
        </Box>
      </Stack>
    </Snackbar>
  );
};

export default Notification;
